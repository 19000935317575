<script setup lang="ts">
const HomeStore = useHome()
const DisIndex = ref(0)

function AdClose() {
  console.log('adClose')
  setTimeout(() => {
    DisIndex.value += 1
  }, 600)
}
</script>

<template>
    <template v-for="(AdcolumnItem, index) in HomeStore.Notice" :key="index">
    <AdcolumnSpecial v-show="DisIndex === index" v-model:model-value="AdcolumnItem.status" :tag="AdcolumnItem.targetUrl" :img="AdcolumnItem.popUrl" @close="AdClose" />
    </template>
</template>

<style>
.banner2{
  img{
    width: 100%;
    height: 100%;
  }
}
</style>
