<script setup lang="ts">
import { BoonApi } from '~/net/apis/boon_api'

// queryBanner /jav/main/queryWelfare
const hotRecord = ref<Boon[]>()
const officialRecord = ref<Boon[]>()
const bannerRecord = ref<Boon[]>()
// const router = useRouter()
async function getList() {
  const { data:resDate } = await BoonApi.list({ welfareType: -1 })
  const data = resDate.sort((a,b)=>{
    const aS = a.tagSort||0
    const bS =  b.tagSort||0
    return aS - bS
  })
  officialRecord.value = data.filter(item => {
    return item.welfareType === 5
  })
  hotRecord.value = data.filter(item => {
    return item.welfareType === 0
  })
  bannerRecord.value = data.filter(item => {
    return item.welfareType === 2
  })
  // console.log('....officialRecord', officialRecord.value)
}
getList()

function clickBannerEl(val: any) {
  openNewWindowAsync(({ openWin }) => openWin(val.targetUrl))
}
</script>

<template>
  <div min-h-screen px-4>
    <van-nav-bar title="福利推荐" />
    <div v-if="bannerRecord?.length" class="banner">
      <MySwiper :banner-url-list="bannerRecord" />
    </div>
    <div class="official-recommendation">
    <p class="title-text ct-e8e8e8">
      <span class="title-line" />
      官方推荐
</p>
    <van-row class="recommendation-items grid-cols-6">
      <van-col
      v-for="(item, index) in officialRecord"
      :key="index" class="recommendation-item" @click="clickBannerEl(item)">
        <div class="item-image h-53px w-53px">
          <ImgWrapper v-if="item.pictureUrl" h-full :src="item?.pictureUrl" />
        </div>
        <p class="mt-1 text-center text-3 ct-e8e8e8">{{ item.name }}</p>
      </van-col>
    </van-row>
  </div>
    <div class="hot-apps">
      <p class="title-text ct-e8e8e8">
        <span class="title-line" />
        热门应用
</p>
        <van-row class="hot-apps-items pb-14">
          <van-col
          v-for="(item, index) in hotRecord"
          :key="index" class="app-item">
          <div class="item-image">
            <ImgWrapper v-if="item.pictureUrl" h-17 w-18 :src="item?.pictureUrl" />
          </div>
            <div class="item-details">
              <div class="item-name">{{ item.name }}</div>
              <BtnDownload text="下载" @click="clickBannerEl(item)" />
            </div>
          </van-col>
        </van-row>
    </div>
    <AdSpecialGroup />
  </div>
  <TheFooter fixed bottom-0 h-50 w-full />
</template>

<style scoped>
.banner{
  margin: 0 auto;
}
.recommendation-items{
  display: grid;
  gap: 5px;
}
.official-recommendation,
.hot-apps {
  margin-top: 20px;
}
.hot-apps-items {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 4px;
}
.app-item{
  display: flex;
  justify-content: space-between;
}
.item-image {
  border-radius: 6px;
  overflow: hidden;
}

.item-name {
  font-size: 13px;
  text-align: center;
  margin-top: 3px;
  color: #e8e8e8;
}

.item-details {
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: space-between;
  margin-left: 10px;
}
.title-line {
  width: 3px;
  height: 21px;
  border-radius: 3px;
  background-color: #fd5f03;
  margin-right: 6px;
  display: inline-block;
}

.title-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 20px;
}
</style>
