<script setup lang="ts">
import { openNewWindowAsync } from '~/utils/helper'

const props = withDefaults(defineProps<Props>(), {})
interface Props {
  bannerUrlList?: Array<any>
  width?: string | '100%'
  height?: string | '100%'
}
const router = useRouter()

function clickBannerEl(val: any) {
  const ishost = val.targetUrl.includes('https://') || val.targetUrl.includes('http://')
  if (val.isLink === 1 && ishost) {
    openNewWindowAsync(({ openWin }) => openWin(val.targetUrl))
  }
  if (val.isLink === 1 && val.linkTarget === 3) {
    router.push(`/details/${getVideoType(val.videoTypeId) || 'movie'}/${val.targetUrl}`)
  }
}
</script>

<template>
  <Swiper
    min-h-149px
    w-343px
    :style="{ width, height }"
    :modules="[SwiperAutoplay, SwiperEffectCreative]"
    :slides-per-view="1"
    :loop="true"
    :pagination="true"
    effect="creative"
    :autoplay="{
      delay: 3000,
      disableOnInteraction: false
    }"
    :creative-effect="{
      prev: {
        shadow: false,
        translate: ['-100%', 0, -1]
      },
      next: {
        translate: ['100%', 0, 0]
      }
    }"
    rounded-2
  >
    <!-- :placeholder="getBannerBgImg"  -->
    <SwiperSlide v-for="(slide, index) in bannerUrlList" :key="index" cursor-pointer @click="clickBannerEl(slide)">
      <ImgWrapper v-if="slide.pictureUrl" :src="slide.pictureUrl" h-full w-full placeholder="/banner-1-defbg.png" />
    </SwiperSlide>
  </Swiper>
</template>
