<script lang="ts" setup>
import { decryptImgUrl } from '~/utils/crypto'

const props = withDefaults(
  defineProps<{
    modelValue: boolean
    img: string
    tag: string
  }>(),
  {
    modelValue: false,
    img: '',
    tag: ''
  }
)
const emits = defineEmits<{
  'update:modelValue': [value: boolean]
  close: [value: boolean]
}>()
function close() {
  emits('update:modelValue', false)
  emits('close', false)
}
const image = ref()
watchEffect(async () => {
  image.value = ''
  image.value = await decryptImgUrl(props.img)
})
</script>

<template>
  <van-overlay :show="modelValue" style="z-index: 2000">
      <div h-screen flex items-center justify-center>
        <div
        pos-relative max-w-full flex flex-col items-center justify-center px-20px class="announcement_warrper"
        @click.stop>
          <div class="absolute left-43% rounded-50% border-2 border-#FFFFFF bottom-[-54px] z-3 p-2" @click="close">
            <BaseIcon name="close" size="1.2" />
          </div>
          <a v-if="tag" :href="tag" target="_blank" class="absolute-center z-2 h-full w-full" />
           <img v-if="!!image" :src="image" class="max-h-xs" />
        </div>
      </div>
  </van-overlay>
</template>

<style scoped>
:deep(.van-loading--vertical) {
  justify-content: center;
}

:deep(.van-calendar__day){
  font-size: 12px;
}
:deep(.van-calendar){
  background: #111425;
}
:deep(.nextTime .van-calendar__bottom-info){
  color: #FD5B03;
}
</style>
