import http from '~/utils/http'


export class BoonApi {
  // @POST('/jav/main/queryWelfare')
  // Future<List<Boon>> list(@Body() BoonParam param);
  static list(params: BoonParam, opt?: HttpOptions) {
    return http.post<Array<Boon>>({
      ...opt,
      url: '/jav/main/queryWelfare',
      body: params
    })
  }
}
